import 'ol/ol.css';
import './style.scss'

import $ from 'jquery';
import Plyr from 'plyr';

import Feature from 'ol/Feature';
import Geolocation from 'ol/Geolocation';
import Map from 'ol/Map';
import MapboxVector from 'ol/layer/MapboxVector';

import Icon from 'ol/style/Icon';

import {Point, Polygon} from 'ol/geom';
import View from 'ol/View';
import {fromLonLat} from 'ol/proj';
import {Circle as CircleStyle, Fill, Stroke, Style} from 'ol/style';
import { OSM, Vector as VectorSource} from 'ol/source';
// import 'ol/source';
import {Tile as TileLayer, Vector as VectorLayer} from 'ol/layer';
import GeoJSON from 'ol/format/GeoJSON';


function svgIcon(rotate = 0, uid = 1) {

	const point = dataJson.points[uid - 1].marker

	return `<svg  width="90" height="90"  viewBox="0 0 90 41.3" xmlns="http://www.w3.org/2000/svg" style="transform:rotate(${rotate}deg)"> ${point}
		</svg>`;
}


// loading view auschnitt
const view = new View({
	// center: fromLonLat([13.3753013, 52.562468]),
	center: fromLonLat([13.38, 52.562468]),
	zoom: 13.5,
});


//Map default styles
const styles = {
	'LineString': new Style({
		stroke: new Stroke({
			color: '#000',
			width: 3,
			lineDash: [7, 12]
		}),
	}),
	'MultiLineString': new Style({
		stroke: new Stroke({
			color: '#000',
			width: 3,
		}),
	}),
	
	'MultiPoint': new Style({
		color: 'orange'
	}),
	
	'Polygon': new Style({
		stroke: new Stroke({
			color: '#000',
			
			width: 1,
		}),
		fill: new Fill({
			color: '#000',
		}),
	}),
	
	'Circle': new Style({
		stroke: new Stroke({
			color: 'red',
			width: 2,
		}),
		fill: new Fill({
			color: 'rgba(0, 255,0,0.2)',
		}),
	}),
};

const styleFunction = function (feature) {
	return styles[feature.getGeometry().getType()];
};



const lines = new VectorLayer({
	source: new VectorSource({
		url:  'https://data.trauminvestitionen.de/geo/lines.json',
		// url:  'https://gist.githubusercontent.com/felixwerner/5bd9c19baccc7a7f8f5cd2e2af26a1e6/raw/97c96ff5745b43c718b269b7467f351ba8f289f6/lines.geojson',
		format: new GeoJSON(),
	}),
	style: styleFunction
});

const dotted = new VectorLayer({
	source: new VectorSource({
		url:  'https://data.trauminvestitionen.de/geo/dotted.json',
		// url:  'https://gist.githubusercontent.com/felixwerner/ac614ac028cd50f07ff368b8341fc4dd/raw/4a89efcc40a73ee364cf3ac9b2a1384f4c358ad7/dotted.json',
		format: new GeoJSON(),
	}),
	style: new Style({
		stroke: new Stroke({
			color: '#ccc',
			width: 3,
			lineDash: [7, 12]
		}),
	}),
});



//Map init
// const map = new Map({
// 	layers: [
// 		new TileLayer({
// 			source: new OSM(),
// 		}),
// 		lines,
// 		// shapes,
// 		// points
// 	],
// 	target: 'map',
// 	view: view,
// });

const map = new Map({
	layers: [
		new MapboxVector({
			styleUrl: 'mapbox://styles/felixwerner/ckrdffiwc1rcu18qir5ff4dyf',
			accessToken: 'pk.eyJ1IjoiZmVsaXh3ZXJuZXIiLCJhIjoiY2tyZGY2YXBjNWJmOTMxcXBtbXczN2pyNyJ9.6CP-wrJv-ZIO6DicRm7kDg',
			zIndex: 0,
			className: 'back-map'
		}),
		lines,
		dotted
	],
	target: 'map',
	view: view,
	zIndex: 0,
	controls: []
	
});



const dataJson = require('./data/geo/data.json');


const shapesForPoint = function (uid) {

	const shapes = new VectorLayer({
		source: new VectorSource({
			url:  'https://data.trauminvestitionen.de/geo/shapes/'+uid+'.json',
			// url:  'https://gist.githubusercontent.com/felixwerner/8c62d45b7bd5d9f40ef2ceba3d404189/raw/dab4d89ebc357397c74072227c91d355148b4e17/6.json',
			format: new GeoJSON(),
		}),
		style: styleFunction
	});

	// shapes.setProperties(data);
	shapes.set('id', uid);
	shapes.set('name', 'shapes');
	shapes.set('typeID', 'shapes');
	shapes.setZIndex(100);
	// shapes.setId(uid);

	map.addLayer(shapes);


	// const point = feature.getGeometry();
	// view.fit(shapes, { padding: [20, 20, 20, 20], zoom: -15 });

	return shapes;
}

const addPoints = function (data)
{
	var points = new VectorLayer({
			source: new VectorSource({
				features: [
					new Feature({
						geometry: 	new Point(fromLonLat(data.point)),
						id: 		data.id,
						name: 		data.name
					})
				]
			}),
			style: new Style({
				image: new Icon({
						opacity: 1,
						src: "data:image/svg+xml;utf8," + encodeURIComponent(svgIcon(data.rotate, data.id)),
						scale: 1,
						className: 'svg-icon'
				}),
				zIndex: 5
			}),
			zIndex: 10000
	});
	
	points.setProperties(data);
	points.set('id', data.id);
	points.set('typeID', 'point');
	points.setZIndex(10000 + data.id);

	
	map.addLayer(points);
}


//init all points

const addPointsToMap = function ()
{
	dataJson.points.forEach((point) => {
		// console.log(point);

		addPoints(point)
	})
}

addPointsToMap()

// console.log(map.getLayers());

const removeFeature = function (uid, type = 'shapes') {
	var layersToRemove = [];

	map.getLayers().forEach(function (layer) {
		if (layer.get('typeID') != undefined && layer.get('typeID') === type && layer.get('id') === uid) {
			layersToRemove.push(layer);
		}
	});

	var len = layersToRemove.length;
	for (var i = 0; i < len; i++) {
		map.removeLayer(layersToRemove[i]);
	}
}

const zoomOnPoint = function (uid) {
	// const point = feature.getGeometry().getExtent()
	
	const point = dataJson.points[uid - 1].point

	map.setView( new View({
		center: fromLonLat(point),
		padding: [0, 5, 200, 5],
		zoom: 16 
	}))
}

//GEo location stuff

const geolocation = new Geolocation({
	// enableHighAccuracy must be set to true to have the heading value.
	trackingOptions: {
	enableHighAccuracy: true,
	},
	projection: view.getProjection(),
});

// enable geo location tracking
geolocation.setTracking("checked");


/* 
// update the HTML page when the position changes.
geolocation.on("change", function () {
	el("accuracy").innerText = geolocation.getAccuracy() + " [m]";
	el("altitude").innerText = geolocation.getAltitude() + " [m]";
	el("altitudeAccuracy").innerText = geolocation.getAltitudeAccuracy() + " [m]";
	el("heading").innerText = geolocation.getHeading() + " [rad]";
	el("speed").innerText = geolocation.getSpeed() + " [m/s]";
}); */


// handle geolocation error.
geolocation.on("error", function (error) {
	console.log(error.message)	
});

const geoLoc = function ()
{
	
	const accuracyFeature = new Feature();
	
	geolocation.on("change:accuracyGeometry", function () {
		// accuracyFeature.setGeometry(geolocation.getAccuracyGeometry());
	});
	
	const positionFeature = new Feature();
	
	positionFeature.setStyle(
		new Style({
			image: new CircleStyle({
				radius: 20,
				fill: new Fill({
					color: '#0603F5',
				}),
				// stroke: new Stroke({
				// 	color: '#fff',
				// 	width: 1,
				// }),
			}),
		})
	);
	
	geolocation.on('change:position', function () {
		const coordinates = geolocation.getPosition();
		positionFeature.setGeometry(coordinates ? new Point(coordinates) : null);
	});
	
	new VectorLayer({
		map: map,
		source: new VectorSource({
			features: [accuracyFeature, positionFeature],
		}),
	});
	
}

geoLoc()

//trigger map click

map.on('singleclick', function (evt) {
	var feature = map.forEachFeatureAtPixel(evt.pixel,
		function (feature, layer) {
			// console.log("feature", feature.get('name'));
			// console.log("layer", layer.get('name'));
			// console.log("layer", layer.get('id'));
			
			openPointInfo(layer, feature);

			return [feature, layer];
		});
});


const closePointInfo = function(uid)
{
	$('.close-x').on('click', function (evt) {
		$('.overlay').removeClass('open').addClass('hide');
		

		audioPause()
		
		closeTrigger(uid)
	})
}

const closeTrigger = function (uid)
{
	
	audioPause()
	
	removeFeature(uid)

	addPoints(dataJson.points[uid - 1])
	
	$('title').empty().text('Trauminvestitionen')
	
	window.history.pushState(null, 'Title', '/');
}

const openPointInfo = function (layer, feature)
{
	const uid = layer.get('id')
	

	if (uid) {
		$('.overlay').removeClass('open').addClass('hide');

		
		for (var i = 1; i <= 9; i++)
		{
			if (i === uid) {
				continue
			}
			
			closeTrigger(i)
		}
		
		zoomOnPoint(uid)

		removeFeature(uid, 'point')

		$('.overlay.location#' + uid).addClass('open');

		$('body').data('open', uid);
		
		// map.removeLayer(layer);
		
		shapesForPoint(uid);

		$('title').empty().text(uid + ' - Trauminvestitionen')

		window.history.pushState(null, 'Titled', '#' + uid);

		closePointInfo(uid)
	}

}



const initUrl =function () {
	const slug = window.location.href.split('#')

	if (slug.length > 0) {
		let uid = parseInt(slug[1])
		if (Number.isInteger(uid)) {
			
			// console.log(uid);
			$('.overlay').removeClass('open').addClass('hide');
			$('.overlay.location#' + uid).addClass('open');
			
			zoomOnPoint(uid)
			shapesForPoint(uid);
			removeFeature(uid, 'point')
		
			
			closePointInfo(uid)
		}
	}
}

initUrl()


// other

toggleOverlay()



const player = Plyr.setup('.audio-file audio', {
	controls: ['play', 'progress', 'current-time']
});

function audioPause() {
	for ( var i = 0; i < player.length; i++ ) {
		player[i].pause();
	}
}

function toggleOverlay()
{
	$('.button').on('click', function (e) {
		e.preventDefault()

		if ($('.page#info').hasClass('open')) {
			$('.page#info').removeClass('open').addClass('hide');

		} else {

			$('.page#info').addClass('open');
		}
	})

	$('.close-x').on('click', function (evt) {
		$('.overlay').removeClass('open').addClass('hide');								
	})

	toggleLegal()
}

function toggleLegal()
{
	$('.toggle-legal').click(function (e) {
		e.preventDefault

		$('.hide-legal').show()
		$(this).hide()
	})
}
